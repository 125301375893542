.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.formfield {
  width: 100%;
  display: block;
  margin: .25em;
  text-align: left;
  font-size: larger;
}
.formfield input {
  width: 75%;
  float: right;
  /*argin-top: .5em;*/
  font-size: larger;
}
.formfieldblock textarea {
  width: 99%;
  height: 10em;
  resize: none;
  float: end;

}
.formfieldblock span {
  width: 99%;
  display: inline-block;
  float: left;
  text-align: left;
}
.formfieldblock {
  text-align: left;
}
.formcommandbar {
  width: 100%;
  display: block;
  padding: 1em;
}
.formcommandbar input {
  margin-right: 2em;
  height: 2.5em;
  font-size: large;
  padding: 0.25em;
}
.Head-IMG {
  width: 40%;
  margin-bottom: 1em;
}
